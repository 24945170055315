import { Box, makeStyles, Typography } from '@material-ui/core'
import { useLocation } from '@reach/router'
import { navigate } from 'gatsby'
import { parse } from 'querystring'
import React, { useEffect, useMemo, useState } from 'react'
import { I18nextProvider } from 'react-i18next'
import { isUserAuthenticated } from '../../utils/isUserAuthenticated'
import chirpLogo from '../assets/images/chirpyest-text-logo.svg'
import axios from '../axios'
import BlockUserInterface from '../components/blockUserInterface'
import CHRButton from '../components/button'
import CountdownRedirect from '../components/countdownRedirect'
import i18n from '../components/i18n'
import JoinWithEmail from '../components/joinModales/joinWithEmail'
import MainJoin from '../components/joinModales/mainJoin'
import CHRSocialMedia from '../components/socialMedia'
import { BREAKPOINTS, ENDPOINTS, ROUTES } from '../constants'
import useBrandJoinFlow, {
  JoinFlowBrandDetails,
} from '../hooks/useBrandJoinFlow'

const styles = makeStyles(() => ({
  pageContainer: {
    display: 'flex',
    height: '100vh',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  contentContainer: {
    marginTop: '5rem',
    width: '60%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [`@media (max-width: ${BREAKPOINTS.lg}px)`]: {
      width: '80%',
    },
    [`@media (max-width: ${BREAKPOINTS.md}px)`]: {
      marginTop: '6rem',
      width: '90%',
    },
  },
  actionContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
    marginTop: '6rem',
    gap: '2rem',
    [`@media (max-width: ${BREAKPOINTS.md}px)`]: {
      gap: '3rem',
    },
  },
  actionItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    gap: '1rem',
    width: '40%',
    [`@media (max-width: ${BREAKPOINTS.lg}px)`]: {
      width: '45%',
    },
    [`@media (max-width: ${BREAKPOINTS.md}px)`]: {
      width: '95%',
    },
  },
  actionText: {
    width: '100%',
    lineHeight: '1.5',
    textAlign: 'left',
    fontSize: '1.112rem',
    [`@media (max-width: ${BREAKPOINTS.md}px)`]: {
      textAlign: 'center',
    },
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: '2rem',
  },
  logo: {
    width: '115px',
    height: 'auto',
    marginBottom: '1rem',
  },
  socials: {
    marginRight: '0.8rem',
  },
}))

const ActionItem = ({
  isJoin = false,
  cashback = 0,
  formattedCashback,
  brandName,
  onCtaClick,
}: {
  isJoin?: boolean
  cashback?: number
  formattedCashback?: string
  brandName: string
  onCtaClick: () => void
}) => {
  const classes = styles()

  const joinText =
    cashback > 0 ? (
      <Box>
        earn up to <strong>{formattedCashback} cash back</strong> from{' '}
        <strong>{brandName}</strong>
      </Box>
    ) : (
      <Box>
        earn cash back from <strong>{brandName}</strong>
      </Box>
    )

  const shopText = (
    <Box>
      no, take me straight to <strong>{brandName}</strong>
    </Box>
  )

  return (
    <div className={classes.actionItem}>
      <div className={classes.actionText}>{isJoin ? joinText : shopText}</div>
      <CHRButton
        label={isJoin ? 'join for free' : `shop ${brandName}`}
        onClick={onCtaClick}
      />
    </div>
  )
}

const extractNumber = (str: string | undefined) => {
  if (!str) {
    return null
  }
  const match = str.match(/[-+]?\d*\.?\d+/)
  return match ? Number(match[0]) : null
}

const ReferralJoin = () => {
  const classes = styles()

  const { search } = useLocation()

  const { userId, username, brandId } = parse(search.replace('?', ''))

  const user = { userId, username } as { userId: string; username: string }

  const [isLoading, setIsLoading] = useState(true)

  const [targetedBrand, setTargetedBrand] = useState<Record<string, any>>()

  const [isJoinFlowActive, setIsJoinFlowActive] = useState(false)

  const [isJoinModalOpen, setIsJoinModalOpen] = useState(false)

  const [isJoinWithEmailModalOpen, setIsJoinWithEmailModalOpen] = useState(
    false
  )

  const [isTermsChecked, setIsTermsChecked] = useState(true)

  const [isNewsletterChecked, setIsNewsletterChecked] = useState(true)

  const [isCountdownActive, setIsCountdownActive] = useState(true)

  const {
    setBrandForJoinFlow,
    generateBrandTrackingLinkForUser,
    clearJoinFlowStorage,
    setJoinFlowReferral,
  } = useBrandJoinFlow()

  useEffect(() => {
    clearJoinFlowStorage()
    // NOTE: This is a strictly set referrlId format
    // if you change this then please update the
    // referral payment calculation cron job in backend
    setJoinFlowReferral(`refjoin:${username}-${userId}`)
    ;(async () => {
      try {
        const {
          data: { data: brand },
        } = await axios.get(
          ENDPOINTS.brandById.replace('brandId', brandId as string)
        )

        const isLoggedIn = await isUserAuthenticated()

        if (Boolean(isLoggedIn) || !brand.isCashbackShownInWebsite) {
          window.location.replace(
            generateBrandTrackingLinkForUser(brand, user, false)
          )
        } else {
          setTargetedBrand(brand)
          setIsLoading(false)
        }
      } catch (error) {
        console.error('error', (error as Error).message)
        navigate(ROUTES.home, { replace: true })
      }
    })()
  }, [brandId, userId])

  const availableCashback = useMemo(() => {
    if (!targetedBrand?.commission) {
      return 0
    }
    const match = (targetedBrand.commission as string).match(/[-+]?\d*\.?\d+/)
    const parsedCommission = match ? match[0] : null

    return Number(
      Number(extractNumber(parsedCommission as string) ?? 0).toFixed(2)
    )
  }, [targetedBrand?.commission])

  const formattedCashback = useMemo(() => {
    if (targetedBrand?.commission?.includes('Fixed')) {
      return `Fixed ${availableCashback}`
    } else {
      return `${availableCashback}%`
    }
  }, [availableCashback, targetedBrand?.commission])

  const onSelectJoin = () => {
    if (!targetedBrand) {
      navigate(ROUTES.home, { replace: true })
      return
    }

    const brandDetails: JoinFlowBrandDetails = {
      source: 'ref-join',
      origLink: window.location.href,
      brandId: brandId as string,
      brandName: targetedBrand.brandName,
      url: targetedBrand.url,
      network: targetedBrand.network,
      trackingLink: targetedBrand.trackingLink,
      commission: targetedBrand.commission,
      isCashbackShownInWebsite: targetedBrand.isCashbackShownInWebsite,
      referrerId: userId as string,
    }

    setIsCountdownActive(false)
    setBrandForJoinFlow(brandDetails)
    setIsJoinFlowActive(true)
    setIsJoinModalOpen(true)
  }

  const onSkipJoin = () => {
    window.location.replace(
      generateBrandTrackingLinkForUser(targetedBrand, user, false)
    )
  }

  if (isLoading) {
    return <BlockUserInterface />
  }

  return (
    <div className={classes.pageContainer}>
      <div className={classes.contentContainer}>
        <div>earn up to {formattedCashback} cash back</div>

        <Typography style={{ fontSize: '3rem', marginTop: '1rem' }}>
          {targetedBrand?.brandName}
        </Typography>

        <div className={classes.actionContainer}>
          <ActionItem
            isJoin
            cashback={availableCashback}
            formattedCashback={formattedCashback}
            brandName={targetedBrand?.brandName as string}
            onCtaClick={() => onSelectJoin()}
          />
          <ActionItem
            brandName={targetedBrand?.brandName as string}
            onCtaClick={() => onSkipJoin()}
          />
        </div>
      </div>

      {isCountdownActive ? (
        <CountdownRedirect
          seconds={10}
          replace
          redirectUrl={generateBrandTrackingLinkForUser(
            targetedBrand,
            user,
            false
          )}
          countdownText={
            <Box>
              redirecting to <strong>{targetedBrand?.brandName}</strong>
            </Box>
          }
        />
      ) : null}

      <div className={classes.footer}>
        <img src={chirpLogo} alt="logo" className={classes.logo} />
        <div className={classes.socials}>
          <CHRSocialMedia size="sm" />
        </div>
      </div>

      {isJoinFlowActive && (
        <I18nextProvider i18n={i18n}>
          <MainJoin
            openStatus={isJoinModalOpen}
            handleClose={() => setIsJoinModalOpen(false)}
            handleFinish={() => {
              setIsJoinModalOpen(false)
              setIsJoinWithEmailModalOpen(true)
            }}
            onIsTermsChecked={() => setIsTermsChecked(prev => !prev)}
            onIsNewsletterChecked={() => setIsNewsletterChecked(prev => !prev)}
          />

          <JoinWithEmail
            openStatus={isJoinWithEmailModalOpen}
            handleClose={() => setIsJoinWithEmailModalOpen(false)}
            handleFinish={() => navigate(ROUTES.personalInfo)}
            isExtensionDownloaded={false}
            isTermsCheckedParent={isTermsChecked}
            isNewsletterCheckedParent={isNewsletterChecked}
            redirectToLogin={() => setIsJoinWithEmailModalOpen(false)}
          />
        </I18nextProvider>
      )}
    </div>
  )
}

export default ReferralJoin
