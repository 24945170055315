import { Box } from '@material-ui/core'
import React, { ReactNode, useEffect, useState } from 'react'

export interface CountdownRedirectProps {
  seconds: number
  redirectUrl: string
  replace?: boolean
  countdownText?: ReactNode
}

function CountdownRedirect({
  seconds = 10,
  redirectUrl,
  replace = false,
  countdownText = 'redirecting in',
}: CountdownRedirectProps) {
  const [countDown, setCountDown] = useState(seconds)

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCountDown(prev => {
        if (prev > 0) {
          return prev - 1
        } else {
          clearInterval(intervalId)
          if (replace) {
            window.location.replace(redirectUrl)
          } else {
            window.location.href = redirectUrl
          }
          return prev
        }
      })
    }, 1000)

    return () => {
      clearInterval(intervalId)
    }
  }, [replace, redirectUrl])

  return (
    <Box textAlign="center" lineHeight={1.75}>
      <div>{countdownText}</div>
      <div>in {countDown} seconds</div>
    </Box>
  )
}

export default CountdownRedirect
